<template>
  <div class="row">
    <div class="col-12 col-md-3 mb-2">
      <input-select
        :label="$t('GERAL.SELECIONE_UMA_OPCAO')"
        v-model="filtro.tipoFormaPagamento"
        :options="opcoes.tiposFormPagamento"
      />
    </div>
    <div class="col-12 col-md-2 mb-2">
      <input-text
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        v-model="filtro.descricao"
      />
    </div>
    <div class="col-12 col-md-auto mb-3">
      <b-button
        class="botao-acao-filtro w-100"
        variant="primary"
        @click="filtrar"
        >{{ $t('GERAL.PESQUISAR') }}</b-button
      >
    </div>
    <div class="col-12 col-md-auto mb-3">
      <b-button
        class="botao-acao-filtro w-100"
        variant="secondary"
        @click="limparFiltro"
        >{{ $t('GERAL.LIMPAR_FILTRO') }}</b-button
      >
      
    </div>
  </div>
</template>
<script>
// UTILS & AUX
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// SERVICES
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// COMPONENTS
import { InputSelect, InputText } from '@/components/inputs';

export default {
  components: {
    InputSelect,
    InputText,
  },
  props: {
    items: { type: [Array] },
  },
  data() {
    return {
      filtro: {
        tipoFormaPagamento: '',
        descricao: '',
      },
      opcoes: {
        tiposFormPagamento: [],
      },
    };
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.buscarTiposFormasDePAramento();
  },
  methods: {
    async buscarTiposFormasDePAramento() {
      try {
        this.$store.dispatch(START_LOADING);

        const { data } = await EnumeradoresService.listar(
          'tipo-formas-pagamento'
        );

        this.opcoes.tiposFormPagamento = data.map((row) => ({
          value: row.id,
          text: row.descricao,
        }));
      } catch (error) {
        mensagem.showErrors(error);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },
    filtrar() {
      this.$emit('filtrar');
    },
    limparFiltro() {
      this.filtro = {
        tipoFormaPagamento: '',
        descricao: '',
      };

      this.$emit('limpar');
    },
  },
};
</script>
