<template>
  <modal
    id="modalFormaPagamento"
    titulo="FINANCEIRO.CADASTRAR_FORMA_PAGAMENTO"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-forma-pagamento :form="form" :opcoes="opcoes" @refs="getRefs" />

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
// Components:
import Modal from '@/components/modal/Modal';
import FormFormaPagamento from './Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormFormaPagamento,
  },
  data() {
    return {
      refs: [],
      opcoes: {
        mesReferencia: [],
        tipoFormaPagamento: [],
      },
    };
  },
  mounted() {
    this.getMesReferencia();
    this.getTipoFormaPagamento();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getMesReferencia() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('meses')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          const mesesCronologicos = [
            'Janeiro',
            'Fevereiro',
            'Março',
            'Abril',
            'Maio',
            'Junho',
            'Julho',
            'Agosto',
            'Setembro',
            'Outubro',
            'Novembro',
            'Dezembro',
          ];
          data.sort((a, b) => {
            const indexA = mesesCronologicos.indexOf(a.text);
            const indexB = mesesCronologicos.indexOf(b.text);
            return indexA - indexB;
          });
          this.opcoes.mesReferencia = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoFormaPagamento() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-formas-pagamento')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoFormaPagamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$emit('salvar', this.form);
    },
  },
};
</script>
