import ApiService from "@/common/api/api.service";

let apiBasePath = 'FormaPagamento'
const FormaPagamentoService = {
    // FUNÇÕES CRUD:
    async criar(form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form);
        return data;
    },
    async editar(form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form);
        return data;
    },
    async excluir(aulaId) {
        let { data } = await ApiService.delete(`${apiBasePath}/${aulaId}`);
        return data;
    },
    // FUNÇÕES DE BUSCA:
    async listar() {
        let { data } = await ApiService.get(`${apiBasePath}`);
        return data;
    },
    async obterPorFiltro(paginacao, filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/paginado`,      
            {
                params: { ...paginacao, ...filtro },
            });
        return data;
    },
    async obterPorId(cursoId) {
        let { data } = await ApiService.get(`${apiBasePath}/${cursoId}`);
        return data;
    },
    async obterPorAnoLetivo(anoLetivoId) {
        let { data } = await ApiService.get(`${apiBasePath}/obter-por-anoLetivo?id=${anoLetivoId}`);
        return data;
    },
};

export default FormaPagamentoService;