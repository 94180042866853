<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="tipoFormaPagamento"
        v-model="form.tipoFormaPagamento"
        :options="opcoes.tipoFormaPagamento"
        type="text"
        :label="$t('FINANCEIRO.TIPO')"
        :placeholder="$t('FINANCEIRO.TIPO')"
        required
      />
    </div>

    <div class="col-12 col-md-6">
      <input-number
        ref="parcelaResidual"
        v-model="form.parcelasResiduais"
        :label="$t('FINANCEIRO.PARCELA_RESIDUAL')"
        :placeholder="$t('FINANCEIRO.PARCELA_RESIDUAL')"
        :min="1"
        decimal
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="diaVencimento"
        v-model="form.diaVencimento"
        :label="$t('Dia de vencimento padrão')"
        :placeholder="$t('Dia de vencimento padrão')"
        :maxLength="31"
        :min="1"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        v-model="form.mesReferencia"
        :options="opcoes.mesReferencia"
        type="text"
        :label="$t('FINANCEIRO.MES_REFERENCIA')"
        :placeholder="$t('FINANCEIRO.MES_REFERENCIA')"
      />
    </div>
  </div>
</template>
<script>
import { InputText, InputNumber, InputSelectSearch } from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
    opcoes: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputNumber,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
