<template>
  <div>
    <titulo-formas-pagamento @cadastrar="openModal" />

    <filtro
      :items="items"
      @limpar="limpar"
      @filtrar="buscarFormasPagamento"
      @atualizar-filtro="atualizarFiltro"
    />

    <Tabela :items="items" @editar="openModal" @excluir="confirmarExclusao" />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="buscarFormasPagamento"
    />

    <modal-forma-pagamento
      :form="modais.formaPagamentoDados"
      :exibir="modais.formaPagamento"
      @salvar="salvar"
      @fechar="closeModal('formaPagamento')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import FormaPagamentoService from '@/common/services/financeiro/formaPagamento.service.js';

// Components:
import Tabela from './components/Tabela';
import ModalFormaPagamento from './components/Modal';
import Filtro from './components/Filtro';
import TituloFormasPagamento from '@/views/financeiro/estrutura/formas-pagamento/components/TituloFormasPagamento';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    TituloFormasPagamento,
    Tabela,
    ModalFormaPagamento,
    Filtro,
    Paginacao,
  },
  data() {
    return {
      items: [],
      filtro: {},
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      modais: {
        formaPagamento: false,
        formaPagamentoDados: {},
      },
    };
  },
  mounted() {
    this.buscarFormasPagamento();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    buscarFormasPagamento() {
      this.$store.dispatch(START_LOADING);
      FormaPagamentoService.obterPorFiltro(this.paginacao, this.filtro)
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES AUXILIARES:
    // FUNÇÕES CRUD:
    salvar(item) {
      item.id ? this.editar(item) : this.criar(item);
    },

    criar(item) {
      this.$store.dispatch(START_LOADING);
      FormaPagamentoService.criar(item)
        .then(() => {
          this.closeModal('formaPagamento');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.FORMA_PAGAMENTO_CRIADA')
          );
          this.buscarFormasPagamento();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editar(item) {
      this.$store.dispatch(START_LOADING);
      FormaPagamentoService.editar(item)
        .then(() => {
          this.closeModal('formaPagamento');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.FORMA_PAGAMENTO_EDITADA')
          );
          this.buscarFormasPagamento();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('FINANCEIRO.FORMA_PAGAMENTO_CONFIRMAR_EXCLUSAO')
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      FormaPagamentoService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.FORMA_PAGAMENTO_DELETADA')
          );
          this.buscarFormasPagamento();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FILTRO
    atualizarFiltro(_filtro) {
      this.filtro = _filtro;
    },

    limpar() {
      this.items = [];
      this.filtro = {};
      this.paginacao = {};
    },

    // FUNÇÕES MODAL
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
