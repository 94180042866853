<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'tipoFormaPagamentoDescricao',
          label: 'Tipo',
          sortable: true,
        },
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: true,
        },
        {
          key: 'entrada',
          label: 'Entrada',
          sortable: true,
          formatter: (value) => {
            return value ? this.$t('GERAL.SIM') : this.$t('GERAL.NAO');
          },
        },
        {
          key: 'parcelasResiduais',
          label: 'Parcela residual',
          sortable: true,
        },
        {
          key: 'diaVencimento',
          label: 'Dia de vencimento padrão',
          sortable: true,
        },
        {
          key: 'diaUtil',
          label: 'Dia útil/corrido',
          sortable: true,
          formatter: (value) => {
            return value ? this.$t('GERAL.SIM') : this.$t('GERAL.NAO');
          },
        },
        {
          key: 'mesReferenciaDescricao',
          label: 'Mês referência',
          sortable: true,
          formatter: (value) => {
            return value == 0 ? '' : value;
          },
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'formaPagamento', item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
  },
};
</script>
